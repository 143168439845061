import { Controlled as CodeMirror } from 'react-codemirror2'
import styled from 'styled-components'

const CodeView = ({ code, mode = 'htmlmixed' }) => {
  const config = {
    mode,
    theme: 'material-palenight'
  }

  return (
    <div className="code-view-container">
      <StyledCodeMirror
        options={{
          ...config,
          indentWithTabs: false,
          lineNumbers: true,
          lineWrapping: true,
          tabSize: 2,
          theme: 'material-palenight'
        }}
        value={code}
      />
    </div>
  )
}

const StyledCodeMirror = styled(CodeMirror)`
  border-radius: 0.125rem;
  overflow: hidden;
`

export default CodeView