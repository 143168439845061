import { useCallback, useEffect, useRef, useState } from 'react'
import ReadMore from './ReadMore'
import Tags from 'pages/Recruiters/components/Tags'

const Bullets = ({ bullets }) => (
  <ul className="list-paragraph__bullets list-paragraph__bullets--ul">
    {bullets.map((bullet, index) => (
      <li key={`bullet_${index}`}>{bullet}</li>
    ))}
  </ul>
)

const Description = ({ additionalInfo, text }) => {
  const descriptionRef = useRef(null)
  const [additional, setAdditional] = useState(false)

  const readmore = text?.match(/(_.*?_)/g)?.pop()
  const desc = readmore ? text?.replaceAll(readmore, `<a href="#">${readmore.replaceAll('_', '')}</a>`) : text

  useEffect(() => {
    let current = descriptionRef?.current || null

    if (current) {
      current.querySelector('a[href="#"]')?.addEventListener('click', e => {
        e.preventDefault()
        console.log('lmao')
        setAdditional(true)
      })
    }

    return () => {
      current = null
    }
  }, [descriptionRef, setAdditional])

  return (
    <>
      <p className="list-paragraph__description" dangerouslySetInnerHTML={{__html: desc}} ref={descriptionRef} />
      {readmore && additional && (
        <p className="list-paragraph__description list-paragraph__description--read-more">{additionalInfo}</p>
      )}
    </>
  )
}

const ListItem = ({
  query,
  heading,
  dates,
  description,
  id,
  title,
  location,
  readmore,
  bullets,
  tags,
  onClick,
}) => {
  const [expanded, setExpanded] = useState(false)
  const handleReadmoreClick = useCallback(
    () => setExpanded(prevState => !prevState),
  [])
  const liProps = onClick
    ? { onClick, style: { cursor: 'pointer' } }
    : {}

  return (
    <li className="list-paragraph" id={id || ''} {...liProps}>
      <div>
        <span className="resume__heading">
          <h2 className="heading">{heading}</h2>
          <h2 className="heading heading--dates">{dates}</h2>
        </span>
        <span className="resume__heading resume__heading--subheading">
          <span>{title}</span>
          {location && (
            <span className="location-wrapper">
              <span className="location-icon"><i className="fa-solid fa-location-dot" /></span>
              <span className="location">{location}</span>
            </span>
          )}
        </span>
        {Array.isArray(description)
          ? description?.map(
            (text, index) => (
              <Description text={text} key={`description-${index}`} additionalInfo={readmore} />
            )
          ): <Description text={description} additionalInfo={readmore} />
        }
        {bullets && (
          <Bullets bullets={bullets} />
        )}
        <br />
        <Tags tags={tags} />
        {readmore && expanded && (
          <>
            <br />
            <ReadMore paragraphs={readmore} />
          </>
        )}
      </div>
      {readmore && (
        <button className="read-more__button" onClick={handleReadmoreClick}>
          <i className={`fal fa-angle-${expanded ? 'up' : 'down'}`} />
        </button>
      )}
    </li>
  )
}

export default ListItem