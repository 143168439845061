export const NAV_ITEMS = [
  {
    route: '/',
    id: 'intro',
    active: false,
    name: 'About me',
    visible: true,
  },
  {
    route: '/wake',
    id: 'intro',
    active: false,
    name: 'About me',
    visible: false,
  },
  {
    route: '/recruiters',
    id: 'recruiters',
    active: false,
    name: 'Recruiters',
    visible: true,
  },
  {
    route: '/learn',
    id: 'learn',
    active: false,
    name: 'Learn',
    visible: true,
    children: [
      {
        route: '/css-flexbox-columns',
        id: 'learn-css-flexbox-columns',
        active: false,
        name: 'Learn to use Flexbox Columns',
        parent: 'learn',
      },
      {
        route: '/css-flexbox-rows',
        id: 'learn-css-flexbox-rows',
        active: false,
        name: 'Learn to use Flexbox Rows',
        parent: 'learn',
      },
      {
        route: '/js-for-of-loop-index',
        id: 'learn-js-for-of-loop-index',
        active: false,
        name: 'Get the index of a For-Of loop in JavaScript',
        parent: 'learn',
      },
      {
        route: '/html',
        id: 'learn-html',
        active: false,
        name: 'HTML',
        parent: 'learn',
      },
      {
        route: '/javascript',
        id: 'learn-javascript',
        active: false,
        name: 'JS',
        parent: 'learn',
      },
    ],
    // children: [
    //   {
    //     route: '/css/flexbox-columns',
    //     id: 'learn-css-flexbox-columns',
    //     active: false,
    //     name: 'Learn Flexbox Columns in CSS',
    //     parent: 'learn',
    //   },
    //   {
    //     route: '/css/flexbox-rows',
    //     id: 'learn-css-flexbox-rows',
    //     active: false,
    //     name: 'Learn Flexbox Rows in CSS',
    //     parent: 'learn',
    //   },
    //   {
    //     route: '/javascript/get-index-of-for-of-loop',
    //     id: 'get-index-of-for-of-loop',
    //     active: false,
    //     name: 'Get the index of a for-of loop in JavaScript',
    //     parent: 'learn',
    //   },
    // ],
  },
]