import { configureStore } from '@reduxjs/toolkit'

import layoutReducer from 'features/layout/layoutSlice'
import userReducer from 'features/user/userSlice'

export default configureStore({
  reducer: {
    layout: layoutReducer,
    user: userReducer
  }
})