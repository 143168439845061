import { useCallback, useContext, useRef, useState } from 'react'
import { sendMessage as sendFbMessage } from 'utils/firebase/messaging'
import { navContext } from 'contexts/Navigation'

const ChatBot = () => {
  const navCtx = useContext(navContext)
  const threadRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(true)
  const [typing] = useState(false)
  const [text, setText] = useState(null)
  const [messages, setMessages] = useState([])

  const toggle = useCallback(() => setVisible(!visible), [setVisible, visible])

  const updateText = (e) => {
    const { target: { value } } = e
    setText(value)
  }

  const sendMessage = useCallback(async () => {
    if (text) {
      setLoading(true)
      // const newId = messages.length
      // await setMessages(prevState => [...prevState, {
      //   id: newId,
      //   user: 'rl79975',
      //   bot: false,
      //   text,
      // }])
      if (threadRef?.current) {
        threadRef.current.scrollTo(0, threadRef.current.offsetHeight)
        await sendFbMessage({text, uid: 'rl79975', cb: (message) => setMessages(prevState => [...prevState, message])})
        setLoading(false)
        setText(null)
      }
    }
  }, [setMessages, setText, text, threadRef, setLoading])

  if (!navCtx?.active?.id === 'recruiters') return null

  const chatbotClassname = visible ? 'chatbot' : 'chatbot chatbot--minimized'

  return (
    <div className={chatbotClassname}>
      <div className="chatbot__container">
        <button className="chatbot__close-button" onClick={toggle}>
          {visible && (
            <i className="fa-duotone fa-angle-down" />
          )}
          {!visible && (
            <i className="fa-duotone fa-angle-up" />
          )}
        </button>
        <h1>Ask a question</h1>
        <div className="thread" ref={threadRef}>
          {messages.length === 0 && (
            <div className="chatbot__placeholder">
              <p>Let me know if you have any:</p>
              <ul>
                <li>Terms you want me to define</li>
                <li>Topics you want me to teach</li>
                <li>Questions about my experience</li>
              </ul>
            </div>
          )}
          {messages.map(message => (
            <div className={`row row--${message.bot ? 'bot' : 'user'}`} key={message.id}>
              <span className={`message message--${message.bot ? 'bot' : 'user'}`}>{message.text}</span>
            </div>
          ))}
          {typing && (
            <div className="row row--bot">
              <span className="message">
                <span className="typing-loader" />
              </span>
            </div>
          )}
        </div>
        <div className="input-form">
          <input type="text" className="input" placeholder="Send a message" disabled={loading} onChange={updateText} onKeyDown={e => {
            if (e.key === 'Enter') {
              sendMessage()
              e.target.value = ''
            }
          }} />
          <button className="input-form-button">
            {!loading && (
              <i className="fa-solid fa-paper-plane-top" onClick={sendMessage} />
            )}
            {loading && (
              <i className="fa-duotone fa-spinner fa-spin-pulse" />
            )}
          </button>
        </div>
      </div>
    </div>
  )
}

export default ChatBot