import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    email: '',
    phone: '',
    password: '',
    confirmationCode: '',
    token: ''
  },
  reducers: {
    setUserDetails: (state, action) => {
      console.log(state, action.payload)
      state.email = action.payload.email
      state.phone = action.payload.phone
      state.password = action.payload.password
      state.name = action.payload.name
    },
    authenticateUser: (state, action) => {
      state.userAuthentication = action.payload
    }
  }
})

export const { authenticateUser, setUserDetails } = userSlice.actions

export default userSlice.reducer