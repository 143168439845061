import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { updatePageTitle } from 'hooks'
import { NAV_ITEMS } from 'hooks/data'
import { navContext } from 'contexts/Navigation'
import Nav from 'pages/Resume/IntroNav'

const Layout = ({ children }) => {
  const [container, setContainer] = useState(null)
  const [navItems, setNavItems] = useState(NAV_ITEMS)
  const [pageRefs, setPageRefs] = useState({})
  const [subnav, setSubnav] = useState(null)
  const [visible, setVisible] = useState(false)
  const [activeLink, setActiveLink] = useState(navItems.find(navItem => navItem.active))
  const { pathname } = useLocation()

  const updateContainer = useCallback(ref => setContainer(ref), [setContainer])
  const toggleNav = useCallback(() => setVisible(!visible), [setVisible, visible])
  const toggleSubnav = useCallback(navItem => setSubnav(navItem), [setSubnav])
  const addPageRef = (ref, id) => setPageRefs(prevState => ({
    ...prevState,
    [id]: ref,
  }))
  const updateRef = useCallback((ref, id) => setNavItems(
    prevState => prevState.map(
      item => ({
        ...item,
        ref: item.id === id
          ? ref
          : item.ref,
      })
    )
  ), [setNavItems])

  const updateLink = useCallback(async (route) => {
    const currentRoute = route || pathname
    const [, parentRoute, childRoute] = currentRoute
      .split('/')
      .map(item => `/${item}`)
    const activeParent = navItems?.find(
      route => route.route === parentRoute
    )
    const activeChild = activeParent?.children?.find(
      route => route.route === childRoute
    )
    const active = activeChild || activeParent

    if (container) {
      updatePageTitle(active.name)

      if (activeParent?.children) {
        toggleSubnav(activeParent)
      } else {
        toggleSubnav()
      }

      setNavItems(prevState => prevState.map(
        navItem => ({
          ...navItem,
          active: navItem.id === activeParent.id,
          children: navItem.id === activeParent.id
            ? navItem.children?.map(child => ({
              ...child,
              active: child.route === childRoute,
            }))
            : navItem.children,
        })
      ))
    }
  }, [container, navItems, pathname, setNavItems, toggleSubnav])

  const scrollToLocation = useCallback(async (id, x, y) => {
    if (container) {
      window.scrollBy(0, 100)
      await container.scrollTo(x, y)
      await document.getElementById(id).scrollTo(0, 0)

      return
    }
  }, [container])

  const handleClick = useCallback(async (id, scrollTop = 0) => {
    const splitId = id.split('-')
    const parentId = splitId.shift()
    const selectedParent = navItems.find(navItem => navItem.id === parentId)
    const selectedChild = selectedParent?.children?.find(navItem => navItem.id === id)
    const selectedRoute = selectedChild?.route
      ? `${selectedParent?.route}${selectedChild?.route}`
      : selectedParent?.route

    setActiveLink(selectedParent)

    await updateLink(selectedRoute)
    await scrollToLocation(id, pageRefs[id].offsetLeft, scrollTop)

    setTimeout(() => setVisible(false), 500)
  }, [navItems, pageRefs, scrollToLocation, updateLink])

  useEffect(() => {
    const pushpop = () => {
      if (pathname !== window.location.pathname) {
        const [, parentRoute, childRoute] = window.location.pathname.split('/')
        const currentParentItem = navItems.find(
          navItem => navItem.route === `/${parentRoute}`
        )
        const currentChildItem = currentParentItem?.children?.find(
          navItem => navItem.route === `/${childRoute}`
        )
        const currentNavItem = currentChildItem || currentParentItem

        handleClick(currentNavItem.id)
      }
    }

    window.addEventListener('popstate', pushpop)

    return () => {
      window.removeEventListener('popstate', pushpop)
    }
  }, [container, handleClick, navItems, pageRefs, pathname, setActiveLink])

  useMemo(() => {    
    const [parent, child] = pathname
      .split('/')
      .filter((item, index) => index > 0)
      .map(path => `/${path}`)

    const active = navItems.find(navItem => navItem.active)
    const currentParent = navItems.find(navItem => navItem.route === parent)
    const currentChild = currentParent?.children?.find(navItem => navItem.route === child)

    if (!active && container) {
      if (currentChild) {
        handleClick(currentChild.id)
      } else {
        handleClick(currentParent.id)
      }
    }
  }, [container, handleClick, navItems, pathname])
  const bodyRef = useRef(null)

  useEffect(() => {
    if (!container && bodyRef?.current) {
      updateContainer(bodyRef.current)
    }
  }, [bodyRef, container, updateContainer])

  return (
    <navContext.Provider value={{
      activeLink,
      container,
      pageRefs,
      addPageRef,
      handleClick,
      navItems,
      toggleNav,
      toggleSubnav,
      subnav,
      visible,
      updateRef,
      updateContainer,
    }}>
      <div className="layout-container">
        <div className="layout-nav-wrapper">
          <Nav />
        </div>
        <div className="layout-wrapper">
          <div className="layout-body" ref={bodyRef}>
            {children}
          </div>
        </div>
      </div>
    </navContext.Provider>
  )
}

export default Layout