export const css = [
  {
    heading: 'Learn to use Flexbox Rows',
    id: 'learn-css-flexbox-rows',
    title: 'CSS Flexbox Rows',
    description: ['Build a horizontal layout using CSS Flexbox, a modern approach to traditional layouts.'],
    url: '/learn/css-flexbox-rows',
  },
  {
    heading: 'Learn to use Flexbox Columns',
    id: 'learn-css-flexbox-columns',
    title: 'CSS Flexbox Columns',
    description: ['Build a vertical layout using CSS Flexbox, a modern approach to traditional layouts.'],
    url: '/learn/css-flexbox-columns',
  },
]

export const js = [
  {
    heading: 'Get the index of a for-of loop in JavaScript',
    id: 'learn-js-for-of-loop-index',
    title: 'JavaScript For-Of Loop',
    description: ['Learn JavaScript\'s for-of loop and how to get the index of each iteration'],
    url: '/learn/js-for-of-loop-index',
  },
]