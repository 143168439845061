import CodeDemo from 'components/CodeDemo'
import CodeView from 'components/CodeView'
import {
  htmlCode1,
  cssCode1,
  cssCode3,
  cssCode4
} from './CSS/FlexboxColumnsCode'
import 'styles/lessons/flexbox.scss'

const Lesson = () => {
  return (
    <div className="home-wrapper">
      <div className="container container--in-box container--dark">
        <div className="post post--main">
          <div className="post__header">
            <div className="post__body">
              <h1>Learn to use Flexbox Columns</h1>
              <p>Build a vertical layout using CSS Flexbox, a modern approach to traditional layouts.</p>
            </div>
          </div>
          <div className="lesson-container">
            <div className="lesson-section">
              <div className="lesson-section__content">
                <p>Let's start with just the DOM. We will write our DOM structure using only <code>&lt;div&gt;</code> elements.</p>

                <CodeView code={htmlCode1} mode="javascript" />
              </div>

              <div className="lesson-section__content lesson-section__content--code-demo">
                <CodeDemo title="Dom only" code={htmlCode1} />
              </div>

              <div className="lesson-section__content">
                <p>These <code>divs</code> are already displaying as a column, but we will still implement the <strong>column</strong> layout from <strong>flexbox</strong> as there may be times where this default behavior has been overridden.</p>

                <p>The styles for the <code>.flex-column</code> class we added to our parent <code>&lt;div&gt;</code> are very straightforward. We can write this either in an external stylesheet or within <code>&lt;style&gt;</code> tags.</p>
                
                <CodeView code={cssCode1} mode="css" />
              </div>
              <div className="lesson-section__content">
                <p>As you can see, there is absolutely no difference except that we added a border. How exciting.</p>
              </div>
              <div className="lesson-section__content lesson-section__content--code-demo">
                <CodeDemo title="Dom + basic CSS" code={`<style>\n\t${cssCode1}\n</style>\n${htmlCode1}`} />
              </div>
              <div className="lesson-section__content">
                <p>The next step is to add spacing between the divs. Flexbox makes this easy with the <code>justify-content</code> property!</p>

                <CodeView code={`${cssCode1.substring(0, cssCode1.length - 2)}\n\tjustify-content: space-between;\n}`} mode="css" />
              </div>
              <div className="lesson-section__content lesson-section__content--code-demo">
                <CodeDemo title="justify-content: space-between" code={`<style>\n\t${cssCode1.substring(0, cssCode1.length - 2)}\n\tjustify-content: space-between;\n}\n</style>\n${htmlCode1}`} />
              </div>
              <div className="lesson-section__content">
                <p>Let's amp up the appeal a bit! We will center the content in our <code>&lt;div&gt;</code> children and make sure they have plenty of <strong>padding</strong> for separation of data.</p>
                <p>We will do this by styling the child <code>&lt;div&gt;</code> elements contained within <code>&lt;div class="flex-column"&gt;</code>. We will also want to make sure our parent <code>&lt;div&gt;</code> fills the entire width of our container so that we can horizontally center our content.</p>

                <CodeView code={cssCode3} mode="css" />
              </div>
              <div className="lesson-section__content lesson-section__content--code-demo">
                <CodeDemo title="Centered + padding" code={`<style>${cssCode3}</style>\n\n${htmlCode1}`} />
              </div>
              <div className="lesson-section__content">
                <p>We can show clear separation of the columns by adding a <code>border-bottom</code> property to each child <code>&lt;div&gt;</code>. Make sure to avoid <em>double borders</em> by utilizing the <strong>CSS</strong> <code>&:last-child</code> selector!</p>

                <CodeView code={`.flex-column div {\n\t...\n\tborder-bottom: none;\n\t...\n}`} mode="css" />
                <br />
              </div>
              <div className="lesson-section__content">
                <CodeView code={`.flex-column div:last-child {\n\tborder-bottom: none;\n}`} mode="css" />

                <p>Here, you can see the that the border widths are now consistent on every cell:</p>
              </div>
              <div className="lesson-section__content lesson-section__content--code-demo">
                <CodeDemo title="Final product" code={`<style>\n${cssCode4}</style>\n\n${htmlCode1}`} />
              </div>
              <div className="lesson-section__content">
                <p>Our completed code looks like this:</p>

                <CodeView code={`<style>\n${cssCode4}\n</style>\n\n${htmlCode1}`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Lesson