export const experience = [
  {
    heading: 'Contract development',
    dates: 'Nov. 2022 - Present',
    title: 'Senior Full Stack Developer',
    location: 'Miami, FL',
    bullets: ['I have been working on various contract projects in the capacity of an individual contributor and tech lead.', 'Spearheaded new development efforts for innovative applications in industries B2B and B2C startups.', 'API integrations & development in e-commerce and insurance industries', 'Leveraged machine learning for use in social media analysis application'],
    tags: ['NextJS', 'Context API', 'React', 'TypeScript', 'NestJS. ExpressJS', 'Firebase', 'Firestore', 'AWS', 'Lambda', 'S3', 'GCP', 'Cloud Functions', 'AWS Amplify', 'PostgreSQL', 'MongoDB', 'MySQL'],
  },
  {
    heading: 'Trend',
    dates: 'Jul. 2022 - Nov. 2022',
    title: 'Senior Full Stack Developer',
    location: 'Austin, TX',
    description: ['I contributed to the architecture and development of Trend\'s web application, which serves as the platform in which businesses discover and employ content creators. My notable contributions include building Lambda functions that apply watermarks to uploaded content from creators, optimizing the UX for a better business experience and increased conversions, and setting up containerized environments with auto-deployments to AWS.'],
    bullets: ['Led refactoring and new development efforts for the client-facing business application', 'Developed Trend\'s V2 business application utilizing NextJS on the front-end and NestJS for the API', 'Implemented containerized environments & CI/CD with Docker, AppRunner and Github Actions', 'Designed & implemented micro-services to watermark user-uploaded content', 'Built in-app chat with daily email summary of unread messages utilizing Firebase Messaging and Google Cloud Scheduler'],
    tags: ['React', 'Redux', 'TypeScript', 'NestJS', 'NextJS', 'Firebase', 'Firestore', 'AWS', 'Lambda', 'S3', 'GCP', 'Cloud Functions', 'Cloud Scheduler', 'Firebase Messaging', 'Docker', 'App Runner', 'Github Actions', 'MongoDB'],
  },
  {
    heading: 'Truepill',
    dates: 'Dec. 2020 - Jun. 2022',
    title: 'Senior Full Stack Developer',
    location: 'Austin, TX',
    description: ['I worked across a variety of teams, performing new development across the Truepill platform. My notable contributions include creating a client integration that automates the ingestion of 10,000+ orders each day, creating a service to enable drop-shipping for customers, bootstrapping an administrative dashboard to enable Truepill\'s customers to manage their clients\' orders made through Truepill, and various developments in Truepill\'s ordering, telehealth and subscription services.', 'Truepill\'s tech stack is JavaScript across the board, employing React and TypeScript on the frontend, Node with TypeScript and GraphQL on the back-end, and deployment across GCP. The majority of my work consists of new feature development, with heavy emphasis on UX, performance and scalability.'],
    bullets: ['Founded "CSER" team, spearheading development of the customer management dashboard', 'Contributed to internal and public SPAs and APIs through new feature development', 'Designed & implemented cloud automation service to facilitate batch order processing & reporting for customers who can not utilize Truepill\'s API', 'Developed drop-shipping micro-service to support third-party customer integrations'],
    tags: ['React', 'Context API', 'NodeJS', 'GraphQL', 'GCP', 'TypeScript', 'MySQL', 'MongoDB'],
  },
  {
    heading: 'Fractal',
    dates: 'Nov. 2019 - Dec. 2020',
    title: 'Senior Full Stack Developer',
    location: 'Austin, TX',
    description: ['I designed and developed an e-commerce platform with a niche focus on mobile conversion optimization. I built a secure and highly performant Node back-end paired with an intuitive React + Redux front end. Product is now being rolled out to various lead generation experts. I built the entire API using Node and TypeScript with MongoDB, making use of Redis for serving relevant data quickly. I integrated a number of third-party APIs including payment processors, CRM and analytics tooling.'],
    bullets: ['Designed & developed public and internal e-commerce API', 'Developed interactive WYSIWYG editor for end-users\' website customization', 'Implemented custom tracking pixel with associated metrics & reporting to analyze user engagement and increase revenue', 'Integrated multiple payment processors and CRMs', 'Performed in-depth analysis of seller and consumer behaviors to aid in the design of the e-commerce platform as well as to optimize the performance of the resultant sales funnels', 'Implemented next-gen caching and publishing to ensure sites loaded in under 1000ms'],
    tags: ['React', 'Redux', 'NodeJS', 'TypeScript', 'Express', 'MongoDB', 'Redis', 'Stripe API', 'Paypal API', 'Klaviyo API'],
  },
  {
    heading: 'Austin App House',
    dates: 'Jun. 2018 - Nov. 2019',
    title: 'Senior Full Stack Developer',
    location: 'Austin, TX',
    description: ['I worked as a Senior Full Stack JavaScript engineer with Austin App House, where my work consisted of greenfield development for various businesses. My work was largely focused on React and React Native with Node/Express back-end services.'],
    bullets: ['Executed contracts working in a full-stack capacity to meet website, web application and mobile application development needs', 'Developed mobile application using React Native to support franchises in their lease management', 'Developed API to facilitate automated payouts for an affiliate membership network', 'Created trading metrics reporting web application for a minor hedge fund'],
    tags: ['React', 'JavaScript', 'NodeJS', 'Vue', 'React Native', 'SASS', 'Paypal API', 'Liquid', 'WordPress', 'PHP'],
  },
  {
    heading: 'S3',
    dates: 'Apr. 2017 - Jun. 2018',
    title: 'Senior Front End Engineer',
    location: 'Austin, TX',
    description: ['I contributed as the lead front-end engineer to design and implement an application to streamline class- action lien-resolution processes. I worked closely with the project manager and designer, collaborating to define the overlying front-end architecture and reusable component library, as well as to identify and address potential pain-points for the end users. This application was extremely data-heavy and required high levels of security, efficiency and customizability.'],
    bullets: ['Developed application front-end with careful state management implementation', 'Designed & implemented custom rendering Ul layouts stored via a JSON templating schema', 'Developed Ul component library for use throughout the front-end application', 'Coordinated with project manager & designer to define wireframes that supported reusable components, responsive design & accessibility', 'Utilized GraphQL to build a middleware for authentication and caching'],
    tags: ['React', 'Redux', 'GraphQL', 'PHP', 'EC2'],
  },
  {
    heading: 'OneLive Media',
    dates: 'Jan. 2017 - Apr. 2017',
    title: 'Lead Full Stack Developer',
    location: 'Austin, TX',
    description: ['I served as the lead full-stack developer at OneLive Media, a company providing the production and distribution of merchandise to artists and labels in the music and entertainment industries. I oversaw multiple developers and managed sprints in order to deliver products in a timely manner whilst maintaining a high standard of quality. I was responsible for bringing multiple applications from concept to launch in short timeframes to meet deadlines.'],
    bullets: ['Led team of 3 software engineers in developing print-on-demand tool integrating with print locations worldwide', 'Managed & maintained 700+ client websites and e-commerce stores', 'Built automation integrating with Sony\'s artist API', 'Developed Shopify application for sales reporting that saved 2,400 hours annually for accounting department', 'Created Wordpress plugin and Shopify application that enabled synchronous management of all client websites'],
    tags: ['JavaScript', 'React', 'Redux', 'Ruby on Rails', 'WooCommerce', 'WordPress', 'PHP', 'Sony Label API', 'Python', 'Shopify API', 'Liquid'],
  },
  {
    heading: 'Mozu',
    dates: 'Oct. 2015 - Jan. 2017',
    title: 'Front End UX Developer',
    location: 'Austin, TX',
    description: ['I worked as a front end developer on the Digital Experience (DX) team at Mozu. I worked with a team to build the UI used to administer e-commerce stores, creating a simple, intuitive experience before unseen in the enterprise e-commerce space.'],
    bullets: ['Created unit tests for Mozu\'s V2 e-commerce store management dashboard', 'Assisted in refactoring of legacy application to facilitate migration from ExtJS to React', 'Developed Ul for new purchase-order feature', 'Fixed styling and functional bugs within the legacy application'],
    tags: ['ExtJS', 'CSS', 'HTML', 'React', 'Redux', 'Karma', 'Jasmine'],
  },
  {
    heading: 'RAYVR',
    dates: 'Jan. 2015 - Aug. 2015',
    title: 'Full Stack Developer',
    location: 'Salisbury, MD',
    description: ['I was responsible for designing and implementing a product marketing web application that would connect product developers with users interested in their respective products. This application involved the integration of multiple third-party APIs, including PayPal, Stripe, Mandrill, and Lob. I built the front-end and back-end of the application employing HTML5, CSS3, jQuery and Bootstrap for the front-end, and Laravel paired with a MySQL database for the back-end. I organized and deployed code through Github.'],
    tags: ['PHP', 'Laravel', 'CSS3', 'HTML5', 'Angular', 'jQuery', 'Bootstrap', 'MySQL', 'API'],
  },
  {
    heading: 'South Moon Under',
    dates: 'Apr. 2014 - Jan. 2015',
    title: 'Web Developer',
    location: 'Berlin, MD',
    description: ['I worked as the in-house developer at South Moon Under to manage their e-commerce website. I worked with their implementation on Demandware to maintain and update their e-commerce website. I initiated the transition of the website itself to be responsive, whereas before it showed only the desktop view. I worked with Bronto and Constant Contact to build emails that were optimized for desktop and for mobile devices.', 'I worked with other departments to act on analytics in order to improve site performance. I worked with design and marketing teams to deliver quality content and generate leads. Other tasks included performing updates and communicate with our hosting provider, along with in-depth SEO and SEM analysis to enhance quality lead-generation.'],
    tags: ['DemandWare', 'Angular', 'HTML', 'CSS', 'jQuery'],
  },
  {
    heading: 'Salisbury University',
    dates: 'Aug. 2010 - Jan. 2014',
    title: 'Computer Science',
    location: 'Salisbury, MD',
    bullets: ['Interned in the university web office', 'Performed maintenance on the university website', 'Developed new features for university website and student portal', 'Refactored existing website'],
  }
]