import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { navContext } from 'contexts/Navigation'

const Section = ({ children, id }) => {
  const navCtx = useContext(navContext)
  const sectionRef = useRef(null)
  const [refStored, setRefStored] = useState(false)
  const [inView, setInView] = useState(false)

  const updateInView = useCallback((section) => {
    setInView(navCtx?.body.scrollLeft === section.offsetLeft)
  }, [navCtx?.body])

  useEffect(() => {
    let current = sectionRef?.current || null

    if (current && !refStored && navCtx) {
      navCtx?.addPageRef(current, id)
      setRefStored(true)
    }

    return () => {
      current = null
    }
  }, [id, navCtx, refStored, sectionRef, setRefStored])

  useEffect(() => {
    let current = sectionRef?.current || null

    if (current && navCtx?.body) {
      updateInView(current)
    }

    return () => {
      current = null
    }
  }, [navCtx?.body, navCtx?.body?.scrollLeft, updateInView])
  const classname = ['layout-section']
  if (inView) classname.push('layout-section--active')

  return (
    <div className={classname.join(' ')} id={id} ref={sectionRef}>
      {children}
      <footer className="footer">&copy; {(new Date()).getFullYear()}&nbsp;&nbsp;&rarr;&nbsp;&nbsp;Ross Libby</footer>
    </div>
  )
}

export default Section