import ListItem from './components/ListItem'
import { experience } from 'data/resume'

const Resume = () => {
  return (
    <div className="home-wrapper" style={{ marginTop: '4rem' }}>
      <div className="container container--in-box container--dark">
        <div className="post post--main">
          <div className="post__header">
            <div className="post__body">
              <h1>Resume</h1>
              <p>Senior Full Stack Engineer with a penchant for challenging projects. Previously lead on multiple e-commerce startups. I bring apps from concept to market quickly!</p>
            </div>
          </div>
          <div className="lesson-container">
            <ul className="list-paragraphs">
              {experience.map((exp, index) => (
                <ListItem key={`exp_${index}`} {...exp} />
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Resume