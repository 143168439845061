import { useCallback, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Auth } from 'aws-amplify'
import { Navigate } from 'react-router-dom'

import { setUserDetails as storeUserDetails } from 'features/user/userSlice'

import {
  Box,
  Button,
  Input,
  Form,
  InputGroup,
  Page
} from 'components/Layout'

const Login = () => {
  const dispatch = useDispatch()
  const [user, setUser] = useState(null)
  const [userDetails, setUserDetails] = useState(useSelector(state => state.user))

  const updateUserDetails = detail => setUserDetails({
    ...userDetails,
    ...detail
  })

  const updateUsername = e => {
    if (e.target.value.indexOf('@') > -1) {
      updateUserDetails({
        ...userDetails,
        email: e.target.value
      })
    } else {
      updateUserDetails({
        ...userDetails,
        phone: e.target.value
      })
    }
  }

  const updatePassword = e => {
    updateUserDetails({
      ...userDetails,
      password: e.target.value
    })
  }

  const loginUser = useCallback(async () => {
    try {
      const result = await Auth.signIn({
        username: userDetails.email || userDetails.phone,
        password: userDetails.password
      })

      setUser(result)
      dispatch(storeUserDetails(result.signInUserSession))
    } catch (err) {
      console.log('Problem with logging in:', err)
    }
  }, [dispatch, userDetails, setUser])

  if (user) return (
    <Navigate to="/" />
  )

  return (
    <Page>
      <Box>
        <h2 className="title">Login to your account</h2>
        <p className="description">Learn from videos and interactive exercises. Sign in to access the most updated lessons, track your progress and build your skills!</p>
        <Form>
          <InputGroup>
            <p className="instructions">Learn from videos and interactive exercises. Sign in to access the most updated lessons, track your progress and build your skills!</p>
          </InputGroup>
          <InputGroup>
            <Input
              defaultValue={userDetails.email || userDetails.phone || ''}
              onChange={updateUsername}
              type="text"
              placeholder="Phone number / email"
            />
            <Input
              defaultValue={userDetails.password || ''}
              onChange={updatePassword}
              type="password"
              placeholder="Password"
            />
            <Button onClick={loginUser}>
              <span>Login to your account</span>
              <i className="fa-solid fa-arrow-right" />
            </Button>
          </InputGroup>
        </Form>
      </Box>
    </Page>
  )
}

export default Login