import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { terms as bracketTerms } from 'data/terms'
import { stats } from 'data/stats'
import CodeView from 'components/CodeView'

const code = `const terms = [{ name: 'name', type: 'string', value: 'Ross' }, ...]
const PREFIX = 'Hey, I\\'m'

const setHeading = (term) => new Promise(
  (resolve, reject) => {
    try {
      const headingElement = document.getElementById('introduction')

      setTimeout(() => {
        headingElement.innerText = \`$\{PREFIX} $\{term}\`

        resolve(true)
      }, 3000)
    } catch (err) {
      reject(err)
    }
  }
)

const cycleTerms = async (terms) => {
  const heading = document.getElementById('introduction')

  for (const term of terms) {
    await setHeading(term)
  }
}

cycleTerms(terms)`

const Intro = () => {
  const [valueIndex, setValueIndex] = useState(0)
  const [terms, setTerms] = useState(bracketTerms.map((term, i) => ({
    ...term,
    active: i === 0,
    isArray: term.type.indexOf('[]') > -1,
  })))

  const nextTerm = useCallback(() => {
    const termIndex = terms.findIndex(term => term.active)

    setTerms(prevState => prevState.map((term, i) => ({
      ...term,
      active: termIndex === terms.length - 1
        ? i === 0
          ? true
          : false
        : i === termIndex + 1,
    })))
  }, [setTerms, terms])

  const changeTerm = useCallback(() => {
    const termIndex = terms.findIndex(term => term.active)
    const activeTerm = terms[termIndex]

    if (activeTerm.isArray) {
      if (valueIndex < activeTerm.value.length - 1) {
        setValueIndex(valueIndex + 1)
      } else {
        setValueIndex(0)
        nextTerm()
      }
    } else {
      nextTerm()
    }
  }, [nextTerm, setValueIndex, terms, valueIndex])

  useEffect(() => {
    const timer = setInterval(() => {
      changeTerm()
    }, 3000)

    return () => {
      clearInterval(timer)
    }
  }, [changeTerm])

  const activeTerm = terms.find(term => term.active)
  const term = activeTerm.type === 'string[]'
    ? {
      ...activeTerm,
      value: activeTerm.value[valueIndex],
    } : activeTerm

  return (
    <div className="about-intro about-intro--horizontal">
      <div>
        <h1 className="about-intro__title">
          <span className="intro-title">
            <span>Hey, I'm</span>
            &nbsp;
            <span className="about-intro__brackets">
              <span className="name-bracket">
                <i className="fa-regular fa-bracket-curly-left"></i>
              </span>
              <span>{term.value}</span>
              <span className="name-bracket">
                <i className="fa-regular fa-bracket-curly-right"></i>
              </span>.
            </span>
          </span>
          <br />
          <span className="name-comment">
            @param &#123; {term.type} &#125;:
            &nbsp;&nbsp;
            <span className="name-comment--name">
              {term.name}
              {term.type === 'string[]' && `[${valueIndex}]`}
            </span>
          </span>
        </h1>
        <StyledStats>
          {stats.sort((a, b) => b.percent - a.percent).map((stat, index) => (
            <StyledStat
              key={`stat-${index}`}
              name={stat.name}
              width={stat.percent}
            />
          ))}
        </StyledStats>
        {/* <div className="about-intro__summary">
          <p>I am a self-taught programmer with a passion for building innovative web applications.</p>
          <p>With extensive experience in the e-commerce industry, I have built and contributed to a variety of e-commerce platforms and scaled multiple brands through strategic marketing initiatives.</p>
          <p>In addition to my technical skills, I have experience leading teams and architecting solutions.</p>
          <p>I am constantly seeking new challenges that enable me to push the limits of my tech stack and deliver impactful solutions for my clients.</p>
          <p>My primary development language is [currently] JavaScript, and I work with all major frameworks and tools. React is my go-to front-end framework for building robust and scalable applications.</p>
        </div> */}
      </div>
      <CodeView code={code} mode="javascript" />
    </div>
  )
}

const StyledStats = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  row-gap: 0.375rem;
  width: 100%;
`

const StyledStat = styled.div`
  background-color: #ffffff;
  border: 4px solid #384270;
  border-radius: 2px;
  box-sizing: border-box;
  height: 40px;
  position: relative;
  width: 100%;

  &::after {
    align-items: center;
    background-color: #384270;
    box-sizing: border-box;
    color: #ffffff;
    content: '${props => props.name}';
    display: flex;
    font-size: 14px;
    font-weight: bold;
    height: 100%;
    left: 0;
    padding: 0 10px;
    position: absolute;
    top: 0;
    transition: all .3s ease;
    width: ${props => props.width}%;
  }
`

export default Intro