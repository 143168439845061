import { useState } from 'react'

import CodeEditor from './CodeEditor'
import Console from './Console'
import validation from 'utils/jsValidator'

import Indicator from './Indicator'

const Javascript = ({
  defaultCode = '',
  validationStructure
}) => {
  const [code, setCode] = useState(defaultCode)
  const [valid, setValid] = useState(null)
  const config = {
    mode: 'javascript',
    theme: 'material-palenight'
  }
  const validate = () => {
    const isValid = validation(validationStructure, code)

    setValid(isValid)

    if (isValid) {
      try {
        // eslint-disable-next-line
        eval(code)
      } catch (error) {
        console.log(error.message)
      }
    } else {
      try {
        // eslint-disable-next-line
        eval(code)
      } catch (error) {
        console.log(error.message)
      }
    }
  }
  const handleClick = () => validate()

  return (
    <div className="code-screen">
      <div className="section section--code">
        <div className="section-header">
          <div className="section-header__column">
            <button className="code-check-button" onClick={handleClick}><i className="fa-solid fa-play" />&nbsp;&nbsp;&nbsp;Run</button>
            <span className="section-header__text">Run your code to see if it works &amp; to check for errors</span>
          </div>
          <Indicator valid={valid} />
        </div>
        <CodeEditor
          code={code}
          config={config}
          update={setCode}
          validate={validate}
        />
      </div>
      <div className="section section--console">
        <Console />
      </div>
    </div>
  )
}

export default Javascript