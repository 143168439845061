const minifyCode = data => data.split('  ').join('').split('{\n').join('{').split('\n').join(';').split(';;').join(';')

const javascriptValidation = (structure, code) => {
  const trimmedCode = code.trim()

  const minifiedCode = minifyCode(trimmedCode)
  const minifiedStructure = minifyCode(structure).split(':var')
  const structureWithVars = minifiedStructure
    .map((item, index) => {
      const customVar = minifiedCode.split(item)[1]
      const customVarCode = customVar
        ? customVar.split(minifiedStructure[index + 1])[0]
        : ''

      return minifiedStructure[index]
        + customVarCode
    })
    .join('')

  return structureWithVars === minifiedCode
}

export default javascriptValidation