import Section from './Section'

const Page = ({ children, id }) => {
  return (
    <Section id={id}>
      {children}
    </Section>
  )
}

export default Page