const classnames = props => {
  const classNames = [
    'registration-input',
    'registration-input--confirmation',
    props.valid !== null && `registration-input--confirmation--${
      props.valid === 'true'
        ? 'valid'
        : 'invalid'
    }`
  ]

  return classNames.join(' ')
}

const ConfirmationInput = (props) => (
  <input
    className={classnames(props)}
    { ...props }
  />
)

export default ConfirmationInput