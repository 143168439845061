import HTML from 'components/HTML'

const htmlCode1 = `<html>
<head>
  <title>Divs &amp; spans are cool I guess</title>
</head>
<body>

  <h1>This is an example of a div</h1>

  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>

  <h2>This is an example of a div <em>with spans</em></h2>

  <p>Lectus urna duis convallis convallis. Lectus sit amet est placerat in egestas erat imperdiet sed. In tellus integer feugiat scelerisque varius morbi. Egestas egestas fringilla phasellus faucibus scelerisque eleifend donec pretium vulputate. Mi ipsum faucibus vitae aliquet nec ullamcorper sit amet. Consequat id porta nibh venenatis cras sed felis. Tincidunt nunc pulvinar sapien et ligula ullamcorper. Ut consequat semper viverra nam libero justo laoreet sit. Felis eget nunc lobortis mattis aliquam faucibus purus in massa. Consectetur adipiscing elit duis tristique sollicitudin nibh sit amet. Fames ac turpis egestas maecenas pharetra convallis. Malesuada bibendum arcu vitae elementum. Pulvinar mattis nunc sed blandit.</p>

</body>
</html>`

const htmlCode2 = `<html>
  <head>
    <title>Divs &amp; spans are cool I guess</title>
  </head>
  <body>
    <h1>This is a giant heading</h1>
  </body>
</html>`

const htmlCode3 = `<html>
<head>
  <title>Divs &amp; spans are cool I guess</title>
</head>
<body>

  <div>
    <h1>This is an example of a div</h1>

    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
  </div>

  <div>
    <h2>This is an example of a div <em>with spans</em></h2>

    <p>Lectus urna duis convallis convallis. <span>Lectus sit amet est placerat in egestas erat imperdiet sed.</span> In tellus integer feugiat scelerisque varius morbi. Egestas egestas fringilla phasellus faucibus scelerisque eleifend donec pretium vulputate. Mi ipsum faucibus vitae aliquet nec ullamcorper sit amet. Consequat id porta nibh venenatis cras sed felis. Tincidunt nunc pulvinar sapien et ligula ullamcorper. Ut consequat semper viverra nam libero justo laoreet sit. <span>Felis eget nunc lobortis mattis aliquam faucibus purus in massa.</span> Consectetur adipiscing elit duis tristique sollicitudin nibh sit amet. Fames ac turpis egestas maecenas pharetra convallis. Malesuada bibendum arcu vitae elementum. Pulvinar mattis nunc sed blandit.</p>
  </div>

</body>
</html>`

const Lesson = () => {
  const htmlValidationStructure1 = {
    html: {
      name: 'html',
      content: {
        head: {
          name: 'head',
          content: {
            title: {
              content: 'string',
              name: 'title'
            }
          }
        },
        body: {
          name: 'body',
          content: {
            div: {
              name: 'div',
              content: {
                h1: {
                  name: 'h1',
                  content: 'string'
                },
                p: {
                  name: 'p',
                  content: 'string'
                }
              }
            },
            div2: {
              name: 'div',
              content: {
                h2: {
                  name: 'h2',
                  content: {
                    em: {
                      name: 'em',
                      content: 'string'
                    }
                  }
                },
                p: {
                  name: 'p',
                  content: {
                    span: {
                      name: 'span',
                      content: 'string'
                    },
                    span2: {
                      name: 'span',
                      content: 'string'
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  const htmlValidationStructure2 = {
    html: {
      name: 'html',
      content: {
        head: {
          name: 'head',
          content: {
            title: {
              content: 'string',
              name: 'title'
            },
            style: {
              name: 'style',
              content: 'string'
            }
          }
        },
        body: {
          name: 'body',
          content: {
            h1: {
              name: 'h1',
              content: 'string'
            }
          }
        }
      }
    }
  }

  const htmlValidationStructure3 = {
    html: {
      name: 'html',
      content: {
        head: {
          name: 'head',
          content: {
            title: {
              content: 'string',
              name: 'title'
            },
            style: {
              content: 'string',
              name: 'style'
            }
          }
        },
        body: {
          name: 'body',
          content: {
            div: {
              name: 'div',
              content: {
                h1: {
                  name: 'h1',
                  content: 'string'
                },
                p: {
                  name: 'p',
                  content: 'string'
                }
              }
            },
            div2: {
              name: 'div',
              content: {
                h2: {
                  name: 'h2',
                  content: {
                    em: {
                      name: 'em',
                      content: 'string'
                    }
                  }
                },
                p: {
                  name: 'p',
                  content: {
                    span: {
                      name: 'span',
                      content: 'string'
                    },
                    span2: {
                      name: 'span',
                      content: 'string'
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  return (
    <div className="home-wrapper">
      <div className="container">
        <div className="post post--main">
          <div className="post__header">
            <div className="post__body">
              <h1>Learn about HTML Elements</h1>
              <p>A primer to get you started writing HTML like <del>a pro</del> someone who's <em>really bored</em></p>
            </div>
          </div>
          <div className="lesson-container">
            <div className="lesson-section">
              <div className="lesson-section__header">
                <h1 className="lesson-section__title"><em>Levels</em> of elements</h1>
              </div>
              <div className="lesson-section__content">
                <p className="lesson-section__text">We have already covered various HTML elements, such as <code>&lt;h1&gt;&lt;/h1&gt;</code> (heading), <code>&lt;p&gt;&lt;/p&gt;</code> (paragraph), and <code>&lt;img /&gt;</code> (image).</p>
                <p className="lesson-section__text">While there are technically <strong>142 HTML <em>elements</em></strong>, you likely will use only a very small number of these throughout your career.</p>
                <p className="lesson-section__text">There are two main types of elements that we will be dealing with on a daily basis: <code>block-level</code> elements and <code>inline</code> elements:</p>
                <ul>
                  <li><code>block</code> elements are treated as separate entities, meaning that browsers will arrange them from top-to-bottom and increase their height as-needed based on their content.</li>
                  <li><code>inline</code> elements are treated as though they are supposed to stay on a single line. Browsers render inline-elements side-by-side.</li>
                </ul>
              </div>
            </div>
            <div className="lesson-section">
              <div className="lesson-section__header">
                <h1 className="lesson-section__title">Let's talk about the <em>Divs</em> and <em>Spans</em></h1>
              </div>
              <div className="lesson-section__content">
                <p className="lesson-section__text">Divs are used to <strong>separate</strong> and <strong>contain</strong> HTML elements. The div element is incredibly powerful for creating intuitive, aesthetic websites. Spans, on the other hand, are typically used to add separation and/or styling to <strong>inline</strong> elements.</p>
                <h3>About the <em>div</em>:</h3>
                <ul>
                  <li>Level: <code>block</code></li>
                  <li>Typical uses: <strong>separating information, organizing data</strong></li>
                </ul>
                <h3>About the <em>span</em>:</h3>
                <ul>
                  <li>Level: <code>inline</code></li>
                  <li>Typical uses: <strong>delineating text</strong></li>
                </ul>
                <p className="lesson-section__text">Check out the code below. We have an <code>&lt;h1&gt;</code>, a <code>&lt;p&gt;</code>, an <code>&lt;h2&gt;</code> and another <code>&lt;p&gt;</code>. Wrap the first <strong>h1 + p</strong> in a <code>&lt;div&gt;</code>, and do the same to the next <strong>h2 + p</strong>.</p>
                <p className="lesson-section__text">After you've done this, go into the second <code>&lt;p&gt;</code> and wrap two <code>&lt;span&gt;</code> elements around a few words.</p>
              </div>
            </div>
            <div className="lesson-section">
              <div className="lesson-section__content">
                <HTML
                  defaultCode={htmlCode1}
                  validationStructure={htmlValidationStructure1}
                />
              </div>
            </div>
            <div className="lesson-section">
              <div className="lesson-section__header">
                <h1 className="lesson-section__title">I hope you like GQ, because we're about to talk about <em>styling</em></h1>
              </div>
              <div className="lesson-section__content">
                <p className="lesson-section__text">Styling is what we use to change the appearance of HTML elements. Styling can influence everything (colors, sizes, layout/alignment, etc.) We will be using a language called <strong>CSS</strong>.</p>
                <p className="lesson-section__text">CSS stands for <strong>C</strong>ascading <strong>S</strong>tyle <strong>S</strong>heets. Make sure you remember that because you will literally never, ever, in your entire career, need to know what CSS stands for.</p>
                <p className="lesson-section__text">CSS is the language we use to write rules that HTML elements must follow. CSS syntax is simple:</p>
                <ul>
                  <li>HTML tag name: <code>h1</code></li>
                  <li>Opening brace: <code>&#x7B;</code></li>
                  <li>Property name, value and semicolon (to indicate that the rule has been set): <code>color: red;</code></li>
                  <li>Closing brace: <code>&#x7D;</code></li>
                </ul>
                <p className="lesson-section__text">There are 240 css properties total. Don't worry about memorizing them - not only will you learn the ones you need through repetition; I created a reference guide showing <strong>every single CSS property.</strong> <a href="/reference/css">Check it out here!</a></p>
                <p className="lesson-section__text">Let's say we have an <code>&lt;h1&gt;</code> element and we want to make the font smaller. We would simply use the <code>font-size</code> property:<br /><code>h1 &#x7B; font-size: 16px; &#x7D;</code></p>
                <p className="lesson-section__text">Let's try this out! We will wrap our CSS in a <code>&lt;style&gt;</code> tag in our website's <code>&lt;head&gt;</code>.</p>
              </div>
            </div>
            <div className="lesson-section">
              <div className="lesson-section__content">
                <HTML
                  defaultCode={htmlCode2}
                  validationStructure={htmlValidationStructure2}
                />
              </div>
            </div>
            <div className="lesson-section">
              <div className="lesson-section__header">
                <h1 className="lesson-section__title">Time to make a <em>layout</em></h1>
              </div>
              <div className="lesson-section__content">
                <p className="lesson-section__text">We used our first code example to wrap our headings and paragraphs in <code>&lt;div&gt;</code> tags. Let's revisit that code and adding styling for those <strong>divs</strong>.</p>
                <p className="lesson-section__text">Our goal is to make the content look much more aesthetically pleasing so that anyone who looks at our website thinks we produce high-quality content.</p>
                <p className="lesson-section__text">In the example below we will style the following tags: <code>&lt;body&gt; | &lt;div&gt; | &lt;span&gt;</code></p>
                <p className="lesson-section__text">We will be using the following properties to style these tags:
                  <br /><code><a href="/reference/css#background-color">background-color</a></code><br />
                  <code><a href="/reference/css#border">border</a></code><br />
                  <code><a href="/reference/css#border-radius">border-radius</a></code><br />
                  <code><a href="/reference/css#box-shadow">box-shadow</a></code><br />
                  <code><a href="/reference/css#box-sizing">box-sizing</a></code><br />
                  <code><a href="/reference/css#color">color</a></code><br />
                  <code><a href="/reference/css#font-family">font-family</a></code><br />
                  <code><a href="/reference/css#font-size">font-size</a></code><br />
                  <code><a href="/reference/css#font-weight">font-weight</a></code><br />
                  <code><a href="/reference/css#padding">padding</a></code><br />
                  <code><a href="/reference/css#line-height">line-height</a></code><br />
                  <code><a href="/reference/css#margin-left">margin-left</a></code><br />
                  <code><a href="/reference/css#margin-right">margin-right</a></code><br />
                  <code><a href="/reference/css#margin-top">margin-top</a></code><br />
                  <code><a href="/reference/css#width">width</a></code></p>
                  <p className="lesson-section__text">Once again, we will start with the <code>&lt;style&gt;</code> tag. We will then style our <code>&lt;body&gt;</code> tag, followed by the <code>&lt;div&gt;</code> and finishing with the <code>&lt;span&gt;</code>.</p>
              </div>
            </div>
            <div className="lesson-section">
              <div className="lesson-section__content">
                <HTML
                  defaultCode={htmlCode3}
                  validationStructure={htmlValidationStructure3}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Lesson