import { useRef, useState } from 'react'

import Search from './Search'
import ListItem from '../Resume/components/ListItem'
import { terms as termsList } from 'data/recruiter-lingo'

const Lesson = () => {
  const contentRef = useRef(null)
  const [terms, setTerms] = useState(termsList)
  const [query, setQuery] = useState(null)

  return (
    <div className="home-wrapper">
      <div className="container container--in-box container--dark">
        <div className="post post--main">
          <div className="post__header">
            <div className="post__body">
              <h1>A rundown of tech-speak</h1>
              <p>Learn what job descriptions are actually asking for — and whether your candidates are making hollow promises!</p>
            </div>
          </div>
          <div className="lesson-container">
            <Search setMatches={setTerms} setQuery={setQuery} />
            <ul className="list-paragraphs" ref={contentRef}>
              {terms.map((term, index) => (
                <ListItem key={`term_${index}`} {...term} query={query} />
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Lesson