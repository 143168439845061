import { createSlice } from '@reduxjs/toolkit'

export const layoutSlice = createSlice({
  name: 'layout',
  initialState: {
    stickyNav: false
  },
  reducers: {
    toggleStickyNav: (state, action) => {
      state.stickyNav = action.payload
    }
  }
})

export const { toggleStickyNav } = layoutSlice.actions

export default layoutSlice.reducer