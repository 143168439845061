import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import CSSProperties from './cssProperties.json'

const PropertyText = ({ active, text }) => {
  const classnames = ['row__text']

  if (active) {
    classnames.push('row__text--active')
  }

  return (
    <span className={classnames.join(' ')}>{text}</span>
  )
}

const PropertyRow = ({ active, property }) => {
  const classnames = ['row']

  if (active) {
    classnames.push('row--active')
  }

  return (
    <div
      className={classnames.join(' ')}
      id={property.id}
    >
      <PropertyText
        active={active}
        text={property.name}
      />
      <PropertyText
        active={active}
        text={property.description}
      />
    </div>
  )
}

const CSSReference = () => {
  const location = useLocation()
  const hash = location.hash.substring(1, location.hash.length)

  useEffect(() => {
    if (document.getElementById(hash)) {
      setTimeout(() => window.scrollTo(0, document.getElementById(hash).offsetTop - 200), 100)
    }
  }, [hash])

  return (
    <div className="code-editor-css-reference">
      {CSSProperties.map(property => (
        <PropertyRow
          active={property.id === hash}
          key={property.id}
          property={property}
        />
      ))}
    </div>
  )
}

export default CSSReference