import CodeView from 'components/CodeView'
import data from './data/for-of'

const Paragraph = ({ text }) => (
  <p>{text}</p>
)

const Code = ({ code }) => (
  <CodeView code={code} mode="javascript" />
)

const Lesson = () => {
  const elements = data.body.map(item => {
    switch (item.type) {
      case 'code':
        return (
          <Code code={item.data} />
        )
      default:
        return (
          <Paragraph text={item.data} />
        )
      }
  })

  return (
    <div className="home-wrapper">
      <div className="container container--dark">
        <div className="post post--main">
          <div className="post__header">
            <div className="post__icon">
              <i className="fa-brands fa-js" />
            </div>
            <div className="post__body">
              <h1>{data.header.title}</h1>
              <p>{data.header.subtitle}</p>
            </div>
          </div>
          <div className="lesson-container">
            <div className="lesson-section">
              <div className="lesson-section__content">
                {elements}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Lesson