import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { navContext } from 'contexts/Navigation'

const NavItem = ({
  active,
  id,
  name,
  nested,
  route,
}) => {
  const { pathname } = useLocation()
  const { handleClick, subnav, updateRef } = useContext(navContext)
  const [refStored, setRefStored] = useState(false)
  const itemRef = useRef(null)
  const classname = ['about-nav-item']
  if (active) classname.push('about-nav-item--active')
  if (nested) classname.push('about-nav-item--nested')

  const setRef = useCallback(async () => {
    let current = itemRef?.current || null

    if (current && updateRef) {
      await updateRef(current, id)
      setRefStored(true)
    }
  }, [id, itemRef, updateRef])

  useEffect(() => {
    let current = itemRef?.current || null

    if (current && !refStored) {
      setRef()
    }

    return () => {
      current = null
    }
  }, [id, itemRef, refStored, setRef])

  return (
    <Link
      onClick={() => handleClick(id, 0)}
      className={classname.join(' ')}
      to={route}
      key={id}
      ref={itemRef}
    >
      <span>{name}</span>
      {active && subnav && pathname.split('/').length > 2 && (
        <span className="child">
          {pathname.split('/').pop()?.split('-')?.shift()?.toUpperCase()}
        </span>
      )}
    </Link>
  )
}

export default NavItem