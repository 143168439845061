import { Link } from 'react-router-dom'
import 'styles/lessons/lessons.scss'

const posts = [
  {
    id: 0,
    title: 'Learn to use Flexbox Rows',
    summary: 'Build a horizontal layout using CSS Flexbox, a modern approach to traditional layouts.',
    url: '/lessons/flexbox-rows',
    icon: <i className="fa-brands fa-css3" />,
  },
  {
    id: 1,
    title: 'Learn to use Flexbox Columns',
    summary: 'Build a vertical layout using CSS Flexbox, a modern approach to traditional layouts.',
    url: '/lessons/flexbox-columns',
    icon: <i className="fa-brands fa-css3" />,
  },
  {
    id: 2,
    title: 'Learn about HTML Elements',
    summary: 'A primer to get you started writing HTML like a pro',
    url: '/lessons/html-divs',
    icon: <i className="fa-brands fa-html5" />,
  },
]

const parseBody = (text) => {
  const split = text.split('`')

  if (split.length % 2 > 0) {
    return split.map((item, index) => {
      if (index % 2 === 0) {
        return <span key={`body-${index}`}>{item}</span>
      }

      return <code>{item}</code>
    })
  }

  return text
}

const Lessons = () => {
  return (
    <div className="home-wrapper">
      <div className="container container--dark">
        <div className="post post--main">
          <div className="lesson-container">
            <ul className="list-paragraphs">
              {posts.map((post) => (
                <li className="list-paragraph list-paragraph--icon" key={post.id}>
                  <span className="post__icon">
                    {post.icon}
                  </span>
                  <span>
                    <Link to={post.url} className="heading">{post.title}</Link>
                    <br />
                    <p className="list-paragraph__description">{parseBody(post.summary)}</p>
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Lessons