import { Link } from 'react-router-dom'
import 'styles/lessons/lessons.scss'

const posts = [
  {
    id: 0,
    title: 'A rundown of tech-speak',
    summary: 'Learn what job descriptions are actually asking for — and whether your candidates are making hollow promises!',
    url: '/recruiters/tech-speak',
    icon: <i className="fa-solid fa-graduation-cap" />,
  },
]

const parseBody = (text) => {
  const split = text.split('`')

  if (split.length % 2 > 0) {
    return split.map((item, index) => {
      if (index % 2 === 0) {
        return <span key={`item-${index}`}>{item}</span>
      }

      return <code>{item}</code>
    })
  }

  return text
}

const Recruiters = () => {
  return (
    <div className="home-wrapper">
      <div className="container">
        <div className="post post--main">
          <div className="lesson-container">
            <ul className="list-paragraphs">
              {posts.map((post) => (
                <li className="list-paragraph list-paragraph--icon" key={post.id}>
                <span className="post__icon">
                  {post.icon}
                </span>
                <span>
                  <Link to={post.url} className="heading">{post.title}</Link>
                  <br />
                  <p className="list-paragraph__description">{parseBody(post.summary)}</p>
                </span>
              </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Recruiters