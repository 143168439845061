import {
  useCallback,
  useState
} from 'react'
import { useDispatch } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { Auth } from 'aws-amplify'

import { setUserDetails as storeUserDetails } from 'features/user/userSlice'

import Toast from 'components/Toast'
import {
  Box,
  Button,
  Input,
  Form,
  InputGroup,
  Page
} from 'components/Layout'

const SignUp = () => {
  const dispatch = useDispatch()
  const [showToast, setShowToast] = useState(false)
  const [toastText, setToastText] = useState('')
  const [user, setUser] = useState(null)
  const [userDetails, setUserDetails] = useState({
    email: '',
    name: '',
    password: '',
    phone: ''
  })

  const toggleToast = useCallback(() => setShowToast(!showToast), [setShowToast, showToast])

  const updateName = e => {
    setUserDetails({
      ...userDetails,
      name: e.target.value
    })
  }

  const updateEmail = e => {
    setUserDetails({
      ...userDetails,
      email: e.target.value
    })
  }

  const updatePhone = e => {
    setUserDetails({
      ...userDetails,
      phone: '+1' + e.target.value
    })
  }

  const updatePassword = e => {
    setUserDetails({
      ...userDetails,
      password: e.target.value
    })
  }

  const registerUser = useCallback(async () => {
    try {
      const { user: userAccount } = await Auth.signUp({
        username: userDetails.email,
        password: userDetails.password,
        attributes: {
          email: userDetails.email,
          name: userDetails.name,
          phone_number: userDetails.phone
        }
      })

      dispatch(storeUserDetails(userDetails))
  
      setUser(userAccount)
    } catch(err) {
      console.error('Problem with sign-up:', err)
      setToastText(err.message)
      toggleToast()
    }
  }, [dispatch, setUser, toggleToast, userDetails])

  if (user) return (
    <Navigate to={`/activate`} />
  )

  return (
    <Page>
      {showToast && (
        <Toast
          status="fail"
          text={toastText}
          toggle={toggleToast}
        />
      )}
      <Box>
        <h2 className="title">Sign up for an account</h2>
        <p className="description">Learn from videos and interactive exercises. Sign up to access the most updated lessons, track your progress and build your skills!</p>
        <Form>
          <InputGroup>
            <p className="instructions">Learn from videos and interactive exercises. Sign up to access the most updated lessons, track your progress and build your skills!</p>
          </InputGroup>
          <InputGroup>
            <Input
              onChange={updateName}
              placeholder="Name"
              type="text"
            />
            <Input
              onChange={updateEmail}
              placeholder="Email"
              type="text"
            />
            <Input
              onChange={updatePhone}
              placeholder="Phone number"
              type="text"
            />
            <Input
              onChange={updatePassword}
              placeholder="Password"
              type="password"
            />
            <Button onClick={registerUser}>
              <span>Sign up for an account</span>
              <i className="fa-solid fa-arrow-right" />
            </Button>
          </InputGroup>
        </Form>
      </Box>
    </Page>
  )
}

export default SignUp