import { useEffect, useRef } from 'react'

const CodeDemo = ({ code, title = 'Code demo' }) => {
  const iframeRef = useRef(null)

  useEffect(() => {
    let current = iframeRef?.current || null

    if (current) {
      current.onload = () => {
        current.contentWindow.document.body.style.margin = 0
        current.style.height = current.contentWindow.document.body.offsetHeight + 'px'
      }
    }

    return () => {
      current = null
    }
  }, [iframeRef])

  return (
    <iframe
      className="code-demo"
      title={title}
      srcDoc={code}
      ref={iframeRef}
    />
  )
}

export default CodeDemo