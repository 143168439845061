import CodeDemo from 'components/CodeDemo'
import CodeView from 'components/CodeView'
import {
  htmlCode1,
  cssCode1,
  cssCode2,
  cssCode3,
} from './CSS/FlexboxColumnsCode'
import 'styles/lessons/flexbox.scss'

const Lesson = () => {
  return (
    <div className="home-wrapper">
      <div className="container container--dark">
        <div className="post post--main">
          <div className="post__header">
            <div className="post__body">
              <h1>Learn to use Flexbox Rows</h1>
              <p>Build a horizontal layout using CSS Flexbox, a modern approach to traditional layouts.</p>
            </div>
          </div>
          <div className="lesson-container">
            <div className="lesson-section">
              <div className="lesson-section__content">
                <p>Let's start with just the DOM. We will write our DOM structure using only <code>&lt;div&gt;</code> elements.</p>

                <CodeView code={htmlCode1} />

                <p>The result is simply a few <code>divs</code> stacked one on top of another.</p>
              </div>
              <div className="lesson-section__content lesson-section__content--code-demo">
                <CodeDemo title="Dom only" code={htmlCode1} />
              </div>
              <div className="lesson-section__content">
                <p>We will now write the styles for the <code>.flex-row</code> class we added to our parent <code>&lt;div&gt;</code>. We can write this either in an external stylesheet or within <code>&lt;style&gt;</code> tags.</p>
                
                <CodeView code={cssCode1} mode="css" />

                <p>Our <code>divs</code> should now line up from left to right, instead of stacking from top to bottom. Also, you may notice that we added a border around these items for aesthetic appeal.</p>
              </div>
              <div className="lesson-section__content lesson-section__content--code-demo">
                <CodeDemo title="Dom + basic CSS" code={`<style>\n\t${cssCode1}\n</style>\n${htmlCode1}`} />
              </div>
              <div className="lesson-section__content">
                <p>The next step is to add spacing between the divs. Flexbox makes this easy with the <code>justify-content</code> property!</p>

                <CodeView code={`${cssCode1.substring(0, cssCode1.length - 2)}\n\tjustify-content: space-between;\n}`} mode="css" />
              </div>
              <div className="lesson-section__content lesson-section__content--code-demo">
                <CodeDemo title="justify-content: space-between" code={`<style>\n\t${cssCode1.substring(0, cssCode1.length - 2)}\n\tjustify-content: space-between;\n}\n</style>\n${htmlCode1}`} />
              </div>
              <div className="lesson-section__content">
                <p>Now we will make it look <em>good</em>. Let's center our content and add padding so that our text is not pushed up against the edges.</p>
                <p>We will do this by styling the child <code>&lt;div&gt;</code> elements contained within <code>&lt;div class="flex-row"&gt;</code>.</p>

                <CodeView code={cssCode2} mode="css" />
              </div>
              <div className="lesson-section__content lesson-section__content--code-demo">
                <CodeDemo title="Centered + padding" code={`<style>${cssCode3}</style>\n\n${htmlCode1}`} />
              </div>
              <div className="lesson-section__content">
                <p>This is looking pretty good, but there is a small visual issue. You may notice the right side of our final cell has a <code>2px</code> border instead of <code>1px</code>. This is because we gave our <strong>row</strong> a border of <code>1px</code>, and we also gave each child <code>&lt;div&gt;</code> the property <code>border-right: 1px ...</code>. We can utilize the <strong>CSS</strong> <code>&:last-child</code> selector to make sure that our final <code>&lt;div&gt;</code> does <em>not</em> have a border on its right side.</p>

                <CodeView code={`.flex-row div:last-child {\n\tborder-right: none;\n}`} mode="css" />

                <p>Here, you can see the that the border widths are now consistent on every cell:</p>
              </div>
              <div className="lesson-section__content lesson-section__content--code-demo">
                <CodeDemo title="Final product" code={`<style>\n${cssCode3}\n\n.flex-row div:last-child {\n\tborder-right: none;\n}\n</style>\n\n${htmlCode1}`} />
              </div>
              <div className="lesson-section__content">
                <p>Our completed code looks like this:</p>

                <CodeView code={`<style>\n${cssCode3}\n\n.flex-row div:last-child {\n\tborder-right: none;\n}\n</style>\n\n${htmlCode1}`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Lesson