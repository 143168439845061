import { useEffect, useRef, useState } from 'react'

import CodeEditor from './CodeEditor'
import validation from 'utils/htmlValidator'

import Indicator from './Indicator'

const HTML = ({
  defaultCode = '',
  validationStructure,
  editorHeight,
}) => {
  const iframeRef = useRef(null)
  const [code, setCode] = useState(defaultCode)
  const [renderedCode, setRenderedCode] = useState(defaultCode || '')
  const [initialDefaultCode] = useState(defaultCode)
  const [valid, setValid] = useState(null)
  const config = {
    mode: 'htmlmixed',
    theme: 'material-palenight'
  }
  const validate = () => {
    const sanitized = sanitizeStyles(code)
    setRenderedCode(sanitized)
    const isValid = validation(validationStructure, code)

    setValid(isValid)
  }
  const handleClick = () => validate()

  useEffect(() => {
    if (initialDefaultCode !== defaultCode) {
      setCode(defaultCode)
      const sanitized = sanitizeStyles(defaultCode)
      setRenderedCode(sanitized)
      // iframeRef.current.setAttribute('srcDoc', code)
    }
  }, [code, defaultCode, iframeRef, initialDefaultCode, setCode])

  return (
    <div className="code-screen">
      <div className="section section--code">
        <div className="section-header">
          <div className="section-header__column">
            <button className="code-check-button" onClick={handleClick}><i className="fa-solid fa-play" />&nbsp;&nbsp;&nbsp;Run</button>
            <span className="section-header__text">Run your code to see if it works &amp; to check for errors</span>
          </div>
          <Indicator valid={valid} />
        </div>
        <CodeEditor
          code={code}
          config={config}
          height={editorHeight}
          update={setCode}
          validate={validate}
        />
      </div>
      <div className="section section--browser">
        <div className="browser-header">
          <input className="browser-address-bar" type="text" disabled={true} value={window.location.href} />
          <i className="fa-solid fa-rotate-right" />
        </div>
        <div className="browser-body" dangerouslySetInnerHTML={{ __html: renderedCode }}>
          {/* <iframe ref={iframeRef}></iframe> */}
        </div>
      </div>
    </div>
  )
}

const sanitizeStyles = (ogDom) => {
  const dom = sanitizeTags(ogDom)
  const styles = dom.split('<style>')[1]?.split('</style>')[0]?.split('\n')
  const modified = styles?.map(item => {
    if (item.indexOf('{') > -1) {
        if (['html', 'body'].includes(item.split('{')[0].trim())) {
            return `.browser-body .faux-${item.trim()}`
        }
        return `.browser-body ${item}`
    }
    return item
  }).join('\n')
  const domStart = dom.split('<style>')[0]
  const domEnd = dom.split('</style>')[1]

  return `${domStart}<style>${modified}</style>${domEnd}`
}

const sanitizeTags = (dom) => {
  const reservedTags = ['body', 'html', '/body', '/html']
  const tags = dom.split('<').map(tag => {
    const tagName = tag.split('>')[0]
    const isClose = tagName.indexOf('/') === 0

    if (reservedTags.includes(tagName)) {
      return isClose
        ? `/div>`
        : `div class="faux-${tagName}">`
    }

    return tag
  }).join('<')

  return tags
}

export default HTML