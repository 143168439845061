import { collection, addDoc } from 'firebase/firestore'
import { db } from 'utils/firebase'

export const sendMessage = async ({text, uid, cb, bot = false}) => {
  console.log(text, uid, cb, bot)
  try {
    const docRef = await addDoc(collection(db, 'messages'), {
      uid,
      text,
      date: (new Date()).getTime(),
      read: false,
      responses: [],
    })

    console.log('Message added with id', docRef.id)
    if (cb) cb({
      id: docRef.id,
      user: uid,
      bot,
      text,
    })

    return docRef.id
  } catch (err) {
    console.error('Error sending message', err)
  }
}