import { useEffect } from 'react'
import { Controlled as CodeMirror } from 'react-codemirror2'
import styled from 'styled-components'

require('codemirror/mode/xml/xml')
require('codemirror/mode/htmlmixed/htmlmixed')
require('codemirror/mode/javascript/javascript')
require('codemirror/mode/css/css')

const CodeEditor = ({ code, config, height, update, validate }) => {
  const handleBeforeChange = (editor, data, value) => update(value)

  useEffect(() => {
    const handleKeyDown = e => {
      if (e.metaKey || e.ctrlKey) {
        if (e.key.toLowerCase() === 's' || e.key === 'Enter') {
          e.preventDefault()
          validate()
        }
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [validate])

  return (
    <StyledCodeMirror
      height={height}
      onBeforeChange={handleBeforeChange}
      options={{
        ...config,
        indentWithTabs: false,
        lineNumbers: true,
        lineWrapping: true,
        tabSize: 2,
        theme: 'material-palenight'
      }}
      value={code}
    />
  )
}

const StyledCodeMirror = styled(CodeMirror)`
  height: ${props => props.height + 'px' || 'auto'};
  overflow: scroll;
`

export default CodeEditor