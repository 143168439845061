import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'
import { Auth } from 'aws-amplify'

import {
  Box,
  ConfirmationInput as Input,
  Form,
  InputGroup,
  Page
} from 'components/Layout'

const parseQueryString = querystring => {
  const exploded = querystring
    .substring(1, querystring.length)
    .replaceAll('&', '::')
    .replaceAll('=', '::')
    .split('::')

  const keys = []
  const values = []

  exploded.forEach((item, index) => {
    if (index % 2 === 0) keys.push(item)
    else values.push(item)
  })

  const queryObject = {}

  keys.forEach((key, index) => queryObject[key] = values[index])

  return queryObject
}

const Confirm = () => {
  const { search } = useLocation()
  const queryParams = parseQueryString(search)
  const [confirmed, setConfirmed] = useState(false)
  const [confirmationCode, setConfirmationCode] = useState(queryParams.activationCode || '')
  const confirmationInputs = [0, 1, 2, 3, 4, 5]
  const storedUserDetails = useSelector(state => state.user)

  const confirmSignUp = useCallback(async () => {
    try {
      await Auth.confirmSignUp(queryParams.username || storedUserDetails.email, confirmationCode)
      setConfirmed(true)
    } catch (err) {
      console.error(err)
    }
  }, [confirmationCode, queryParams.username, storedUserDetails.email])

  const autoConfirm = useCallback(async () => {
    if (!isNaN(confirmationCode) && confirmationCode.length === 6) {
      await confirmSignUp()
    }
  }, [confirmationCode, confirmSignUp])

  const handlePaste = useCallback((e) => {
    e.stopPropagation()
    e.preventDefault()
    document.activeElement.blur()

    const text = e.clipboardData.getData('text/plain')

    if (/^\d+$/.test(text) && text.length === 6) {
      for (let i = 0; i < text.length; i++) {
        setConfirmationCode(text)
      }
    }
  }, [setConfirmationCode])

  useEffect(() => {
    document.addEventListener('paste', handlePaste)

    autoConfirm()

    if (!isNaN(confirmationCode) && confirmationCode.length === 6) {
      confirmSignUp()
    }

    return () => document.removeEventListener('paste', handlePaste)
  }, [autoConfirm, confirmationCode, confirmSignUp, handlePaste])

  if (confirmed) return (
    <Navigate to="/login" />
  )

  return (
    <Page>
      <Box>
        <h2 className="title">Confirm your account</h2>
        <p className="description">Check your email for a 6-digit code to verify your account.</p>
        <Form>
          <InputGroup confirmation="true">
            {confirmationInputs.map((input, index) => (
              <Input
                valid={!confirmationCode[index] ? null : !isNaN(confirmationCode[index]) ? "true" : "false"}
                type="tel"
                value={confirmationCode[index] || ''}
                key={`confirmation-code-${index}`}
                maxLength={1}
                onChange={e => {
                  e.target.blur()
                  if (e.target.nextElementSibling) e.target.nextElementSibling.focus()
                  setConfirmationCode(confirmationCode.substring(0, index) + e.target.value + confirmationCode.substring(index + 1, confirmationCode.length))
                }}
                inputMode="numeric"
                onClick={e => {
                  e.target.setSelectionRange(0, 1)
                }}
                onFocus={e => {
                  e.target.setSelectionRange(0, 1)
                }}
                placeholder="0"
              />
            ))}
          </InputGroup>
        </Form>
      </Box>
    </Page>
  )
}

export default Confirm