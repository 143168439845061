import { useLocation } from 'react-router-dom'
import ChatBot from 'components/ChatBot'
import Page from 'pages/Page'
import { Recruiters as RecruitersPage } from 'pages'

const Recruiters = () => {
  const { pathname } = useLocation()

  return (
    <Page id="recruiters">
      <RecruitersPage />
      {pathname === '/recruiters' && (
        <ChatBot />
      )}
    </Page>
  )
}

export default Recruiters