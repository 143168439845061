const ReadMore = ({ paragraphs }) => {
  return (
    <div className="read-more">
      {paragraphs.map((paragraph, index) => (
        <p
          key={`paragraph-${index}`}
          className="list-paragraph__description list-paragraph__description--read-more"
        >{paragraph}</p>
      ))}
    </div>
  )
}

export default ReadMore