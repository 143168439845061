import {
  BrowserRouter as Router,
  Route,
  Routes,
} from 'react-router-dom'
import Amplify from 'aws-amplify'
import { ApolloProvider } from '@apollo/client'

import CSSReference from 'pages/Reference/CSS'

import { client } from 'ApolloClient/client'
import Lesson from 'pages/Lesson'
import FlexboxColumns from 'pages/Lessons/FlexboxColumns'
import FlexboxRows from 'pages/Lessons/FlexboxRows'
import ForOfLoop from 'pages/Lessons/javascript/ForOfLoop'
import Recruiters from 'pages/Recruiters'
import TechSpeak from 'pages/Recruiters/TechSpeak'
import Lessons from 'pages/Lessons'
import Login from 'pages/Login'
import SignUp from 'pages/SignUp'
import Confirm from 'pages/Confirm'
import Pages from 'site-pages'

import HTML from 'components/HTML'
import Javascript from 'components/Javascript'
import 'styles/main.scss'

Amplify.configure({
  Auth: {
    region: 'us-east-1',
    userPoolId: 'us-east-1_ZUnd6C8Jp',
    userPoolWebClientId: '3nf5lq4392rhsvpfitp9jdr9q2'
  }
})

const htmlValidationStructure = {
  html: {
    head: {
      title: 'string',
      link: {
        props: {
          rel: 'stylesheet',
          href: 'style.css'
        }
      }
    },
    body: {
      div: {
        p: 'string'
      }
    }
  }
}

const jsValidationStructure = `function writeMessageToUser(:var) {
  console.log(\`Hello, \${:var}!\`)
}

const lol = () => alert(:var)

writeMessageToUser(:var)
lol()`

function App() {
  return (
    <ApolloProvider client={client}>
      <Router>
        <Routes>
          <Route path="/" element={<Pages />} />
          <Route path="/wake" element={<Pages />} />
          <Route path="/recruiters" element={<Pages />} />
          <Route path="/portfolio" element={<Pages />} />
          <Route path="/learn/css-flexbox-columns" element={<Pages />} />
          <Route path="/learn/css-flexbox-rows" element={<Pages />} />
          <Route path="/learn/js-for-of-loop-index" element={<Pages />} />
          <Route path="/learn" element={<Pages />} />
          <Route path="/contact" element={<Pages />} />
          <Route path="/login" element={<Login />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/activate" element={<Confirm />} />
          <Route path="/code-validation/html" element={<HTML validationStructure={htmlValidationStructure} />} />
          <Route path="/code-validation/javascript" element={<Javascript validationStructure={jsValidationStructure} />} />
          {/* <Route path="/portfolio" element={<Portfolio />} /> */}
          <Route path="/lessons/html-divs" element={<Lesson />} />
          <Route path="/lessons/flexbox-rows" element={<FlexboxRows />} />
          <Route path="/lessons/flexbox-columns" element={<FlexboxColumns />} />
          <Route path="/lessons/js/for-of-loop" element={<ForOfLoop />} />
          <Route path="/lessons" element={<Lessons />} />
          <Route path="/recruiters" element={<Recruiters />} />
          <Route path="/recruiters/tech-speak" element={<TechSpeak />} />
          {/* <Route path="/resume" element={<Resume />} /> */}
          <Route path="/reference/css" element={<CSSReference />} />
        </Routes>
      </Router>
    </ApolloProvider>
  )
}

export default App