const Toast = ({
  status = 'success',
  text = '',
  toggle
}) => {
  const classnames = ['notification-toast', `notification-toast--${status}`].join(' ')
  return (
    <div className={classnames}>
      <span>{text}</span>
      <i className="fa-solid fa-close" onClick={toggle} />
    </div>
  )
}

export default Toast