const Indicator = ({ valid = null }) => {
  const classnames = ['code-editor__indicator']

  if (typeof valid === 'boolean') {
    classnames.push(`code-editor__indicator--${valid ? 'valid' : 'invalid'}`)
  }

  return (
    <span className={classnames.join(' ')}>
      <i className="fa-solid fa-circle-small" />
    </span>
  )
}

export default Indicator