export const stats = [
  {
    name: 'JavaScript',
    percent: 100,
  },
  {
    name: 'Node',
    percent: 100,
  },
  {
    name: 'TypeScript',
    percent: 94,
  },
  {
    name: 'MongoDB',
    percent: 88,
  },
  {
    name: 'AWS',
    percent: 78,
  },
  {
    name: 'GraphQL',
    percent: 70,
  },
]