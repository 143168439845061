import { useCallback, useState, useRef } from 'react'
import { terms as termsList } from 'data/recruiter-lingo'

const Search = ({ setMatches, setQuery }) => {
  const inputRef = useRef(null)
  const [loading, setLoading] = useState(false)

  const killLoading = useCallback(async (duration = 500, callback) =>
    await setTimeout(() => {
      setLoading(false)
      callback()
    }, 500), [setLoading]
  )

  const handleSearch = useCallback(async (e) => {
    const query = e.target.value

    setLoading(true)

    if (!query.length) {
      await killLoading(350, async () => {
        await setMatches(termsList)
        setQuery(query)
      })
    } else {
      if (query[0] === '#') {
        if (query.length > 1) {
          const matches = termsList.filter(
            term => term.tags.filter(
              tag => tag.toLowerCase() === query.substring(1, query.length).toLowerCase()
            ).length
          )

          await killLoading(500, async () => {
            await setMatches(matches)
            setQuery(query)
          })
        }
      } else {
        const matches = termsList.filter(term =>
          term.heading?.toLowerCase().indexOf(query.toLowerCase()) > -1
            || term.name?.toLowerCase().indexOf(query.toLowerCase()) > -1
            || term.tags?.filter(tag => tag.toLowerCase().indexOf(query.toLowerCase()) > -1).length
            || term.description?.toLowerCase().indexOf(query.toLowerCase()) > -1
            || term.bullets?.filter(item => item.toLowerCase().indexOf(query.toLowerCase()) > -1).length
        )

        await killLoading(500, async () => {
          setMatches(matches)
          setQuery(query)
        })
      }
    }
  }, [killLoading, setLoading, setMatches, setQuery])

  return (
    <div className="post__search">
      <input
        ref={inputRef}
        type="text"
        placeholder="Search"
        onChange={handleSearch}
      />
      {loading && (
        <span className="loading">
          <i className="fa-duotone fa-spinner fa-spin-pulse" />
        </span>
      )}
      <button className="filter">
        <i className="fa-light fa-sliders" />
      </button>
    </div>
  )
}

export default Search