import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Page from 'pages/Page'
import { Intro, Resume } from 'pages'

const Home = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    const canWakeLock = () => 'wakeLock' in navigator
    let wakelock
    const lockWakeState = async () => {
      if (!canWakeLock()) return

      try {
        wakelock = await navigator.wakeLock.request()
        wakelock.addEventListener('release', () => console.log('Screen wake state locked:', !wakelock.released))
        console.log('Screen wake state locked:', !wakelock.released)
      } catch (err) {
        console.error('Failed to lock wake state with reason:', err.message)
      }
    }

    if (pathname === '/wake') {
      lockWakeState()
    }

    return () => {
      if (wakelock) {
        wakelock.release()
        wakelock = null
      }
    }
  }, [pathname])

  return (
    <Page id="intro">
      <Intro />
      <Resume />
    </Page>
  )
}

export default Home