import Layout from 'pages/Layout'
import Home from './Home'
import Recruiters from './Recruiters'
import Learn from './Learn'
import FlexboxColumns from './FlexboxColumns'
import FlexboxRows from './FlexboxRows'
import ForOfLoop from './ForOfLoop'

const Pages = () => (
  <Layout>
    <Home />
    <Recruiters />
    <Learn />
    <FlexboxColumns />
    <FlexboxRows />
    <ForOfLoop />
  </Layout>
)

export default Pages