import { useEffect, useRef } from 'react'
import ConsoleLogHTML from 'console-log-html'

const Console = () => {
  const loggerRef = useRef(null)
  const preRef = useRef(null)

  useEffect(() => {
    ConsoleLogHTML.connect(loggerRef.current, null, false)

    return () => ConsoleLogHTML.disconnect()
  }, [preRef])

  return (
    <pre style={{
      lineHeight: '.5rem',
      overflow: 'scroll'
    }} ref={preRef}>
      <ul className="code-editor-console__list" ref={loggerRef} />
    </pre>
  )
}

export default Console