import { createContext, useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { updatePageTitle } from 'hooks'
import { NAV_ITEMS } from 'hooks/data'

export const navContext = createContext()

const NavProvider = ({ children }) => {
  const [container, setContainer] = useState(null)
  const [navItems, setNavItems] = useState(NAV_ITEMS)
  const [pageRefs, setPageRefs] = useState({})
  const [subnav, setSubnav] = useState(null)
  const [visible, setVisible] = useState(false)
  const active = navItems.find(navItem => navItem.active)
  const { pathname } = useLocation()

  const updateContainer = useCallback(ref => setContainer(ref), [setContainer])
  const toggleNav = useCallback(() => setVisible(!visible), [setVisible, visible])
  const toggleSubnav = useCallback(navItem => setSubnav(navItem), [setSubnav])
  const updatePageRef = useCallback(
    async (ref, id) => setPageRefs({
      ...pageRefs,
      [id]: ref,
    })
  , [pageRefs, setPageRefs])
  const updateRef = useCallback((ref, id) => setNavItems(
    prevState => prevState.map(
      item => ({
        ...item,
        ref: item.id === id
          ? ref
          : item.ref,
      })
    )
  ), [setNavItems])

  const updateLink = useCallback(async (route) => {
    const currentRoute = route || pathname
    const active = navItems?.find(
      route => route.route === currentRoute || pathname
    )

    if (container) {
      updatePageTitle(active.name)

      if (active?.children) {
        toggleSubnav(active)
      } else {
        toggleSubnav()
      }

      setNavItems(prevState => prevState.map(
        navItem => ({
          ...navItem,
          active: navItem.id === active.id,
        })
      ))
    }
  }, [container, navItems, pathname, setNavItems, toggleSubnav])

  const scrollToLocation = useCallback(async (id, x, y) => {
    if (container) {
      window.scrollBy(0, 100)
      await container.scrollTo(x, y)
      await document.getElementById(id).scrollTo(0, 0)

      return
    }
  }, [container])

  const handleClick = useCallback(async (id, scrollTop = 0) => {
    const selectedNavItem = navItems.find(navItem => navItem.id === id)

    await updateLink(selectedNavItem.route)
    await scrollToLocation(id, pageRefs[id].offsetLeft, scrollTop)

    setTimeout(() => setVisible(false), 500)
  }, [navItems, pageRefs, scrollToLocation, updateLink])

  useEffect(() => {
    const pushpop = () => {
      if (pathname !== window.location.pathname) {
        const currentNavItem = navItems.find(
          navItem => navItem.route === window.location.pathname
        )

        handleClick(currentNavItem.id)
      }
    }

    window.addEventListener('popstate', pushpop)

    return () => {
      window.removeEventListener('popstate', pushpop)
    }
  }, [container, handleClick, navItems, pageRefs, pathname])

  useMemo(() => {
    const active = navItems.find(navItem => navItem.active)
    const current = navItems.find(navItem => navItem.route === pathname)

    if (!active && container) {
      handleClick(current.id)
    }
  }, [container, handleClick, navItems, pathname])

  return (
    <navContext.Provider value={{
      active,
      container,
      pageRefs,
      addPageRef: updatePageRef,
      handleClick,
      navItems,
      toggleNav,
      toggleSubnav,
      subnav,
      visible,
      updateRef,
      updateContainer,
    }}>
      {children}
    </navContext.Provider>
  )
}

export default NavProvider