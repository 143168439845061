import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { navContext } from 'contexts/Navigation'
import ListItem from '../Resume/components/ListItem'
import { css, js } from './data/lessons'

import FlexboxColumns from 'pages/Lessons/FlexboxColumns'
import FlexboxRows from 'pages/Lessons/FlexboxRows'
import ForOfLoop from 'pages/Lessons/javascript/ForOfLoop'

const componentSwitch = (id) => {
  switch (id) {
    case 'learn-css-flexbox-rows':
      return <FlexboxRows />
    case 'learn-css-flexbox-columns':
      return <FlexboxColumns />
    case 'learn-js-for-of-loop-index':
      return <ForOfLoop />
    default:
      return null
  }
}

const Learn = () => {
  const { handleClick } = useContext(navContext)
  const navigate = useNavigate()
  const [posts] = useState([...css, ...js].map((post) => ({
    ...post,
    component: componentSwitch(post.id),
    expanded: false,
  })))

  return (
    <div className="home-wrapper">
      <div className="container container--in-box container--dark">
        <div className="post post--main">
          <div className="post__header">
            <div className="post__body">
              <h1>Learn</h1>
              <p>Learn to web development using HTML, CSS and JavaScript with my step-by-step tutorials!</p>
            </div>
          </div>
          <div className="lesson-container">
            <ul className="list-paragraphs">
              {posts.map((lesson, index) => (
                <ListItem
                  key={`lesson_${index}`}
                  {...lesson}
                  onClick={async () => {
                    await navigate(lesson.url)
                    handleClick(lesson.id, 0)
                  }}
                />
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Learn