import { useContext } from 'react'
import Underline from './components/Underline'
import { navContext } from 'contexts/Navigation'

import NavItem from './components/NavItem'

const IntroNav = () => {
  const { activeLink, navItems, toggleNav, visible} = useContext(navContext)

  const navClassname = ['nav']
  const navContainerClassname = ['about-intro-nav']
  const mobileNavClassname = ['mobile-nav-button']

  if (visible) {
    navClassname.push('nav--visible')
    mobileNavClassname.push('mobile-nav-button--visible')
  }

  return (
    <>
      <div className={navContainerClassname.join(' ')}>
        <div className="about-intro-nav__mobile-wrapper">
          <h1 className="logo">
            <span>Ross Libby</span>
            {activeLink?.id !== 'intro' && (
              <span className="breadcrumb">&nbsp;&nbsp;<i className="fa-light fa-angle-right" />&nbsp;&nbsp;{activeLink?.name}</span>
            )}
          </h1>
          <button className={mobileNavClassname.join(' ')} onClick={toggleNav}>
            <span className="bar" />
            <span className="bar" />
          </button>
        </div>
        <nav className={navClassname.join(' ')}>
          {
            navItems?.filter(item => item.visible).map(link => (
                <NavItem
                  active={link.active}
                  id={link.id}
                  key={link.id}
                  name={link.name}
                  route={link.route}
                />
              ))
          }
          <Underline />
        </nav>
      </div>
    </>
  )
}

export default IntroNav