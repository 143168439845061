import { createContext, useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { NAV_ITEMS } from './data'

export const updatePageTitle = (pageName) => {
  const SUFFIX = '// Ross Libby' // append this to the title on every page
  document.title = `${pageName} ${SUFFIX}`
}

export const usePageTitle = (routes = []) => {
  const SUFFIX = '// Ross Libby' // append this to the title on every page
  const { pathname } = useLocation()
  const [path, setPath] = useState(null)

  const getCurrentRoute = useCallback(() => {
    const current = routes.find(route => route.route === pathname)
    setPath(current.route)

    return current
  }, [pathname, routes])

  const changeTitle = useCallback(() => {
    const current = getCurrentRoute()
    const newTitle = `${current.name} ${SUFFIX}`

    document.title = newTitle
  }, [getCurrentRoute, SUFFIX])

  useEffect(() => {
    if (pathname !== path) {
      changeTitle()
    }
  }, [changeTitle, path, pathname])
}

export const useSiteNavigation = (containerRef) => {
  const { pathname } = useLocation()
  const [container, setContainer] = useState()
  const [navItems, setNavItems] = useState(NAV_ITEMS)
  const [pageRefs, setPageRefs] = useState({})
  const [subnav, setSubnav] = useState(null)
  const [visible, setVisible] = useState(false) // mobile nav visibility

  const updateContainer = useCallback((elementRef) => setContainer(elementRef), [setContainer])

  useEffect(() => {
    if (!container) updateContainer(containerRef)
  }, [container, containerRef, updateContainer])

  // toggle mobile nav visibility
  const toggleNav = useCallback(() => setVisible(!visible), [setVisible, visible])

  // toggle subnav visibility
  const toggleSubnav = useCallback((navItem) => setSubnav(navItem), [setSubnav])

  const updatePageRef = useCallback(async (ref, id) => {
      console.log('Adding reference for ID', id)
      console.log('previous state:', pageRefs)
      console.log('updated state:', {
        ...pageRefs,
        [id]: ref,
      })
      setPageRefs({
        ...pageRefs,
        [id]: 'lol',
      })
    }, [pageRefs, setPageRefs])

  // update current div reference for navigational scrolling
  const updateRef = useCallback((ref, id) => setNavItems(
    prevState => prevState.map(
      item => ({
        ...item,
        ref: item.id === id
          ? ref
          : item.ref
      })
    )
  ), [setNavItems])

  const updateLink = useCallback(async (route) => {
    const currentRoute = route || pathname
    const active = navItems?.find(
      route => route.route === currentRoute || pathname
    )

    if (container) {
      updatePageTitle(active.name)

      if (active?.children) {
        toggleSubnav(active)
      } else {
        toggleSubnav()
      }

      setNavItems(prevState => prevState.map(
        navItem => ({
          ...navItem,
          active: navItem.id === active.id,
        })
      ))
    }
  }, [container, navItems, pathname, setNavItems, toggleSubnav])

  const scrollToLocation = useCallback(async (id, x, y) => {
    if (container) {
      window.scrollBy(0, 100)
      await container.scrollTo(x, y) // is this line no longer necessary?
      await document.getElementById(id).scrollTo(0, 0)

      return
    }
  }, [container])

  const handleClick = useCallback(async (id, scrollTop = 0) => {
    console.log('Do we have a container?', container)
    console.log('Do we have page references?', pageRefs)
    const selectedNavItem = navItems.find(navItem => navItem.id === id)

    await updateLink(selectedNavItem.route)
    await scrollToLocation(id, pageRefs[id].offsetLeft, scrollTop)

    setTimeout(() => setVisible(false), 500) // hide mobile nav after page location has finished updating
  }, [container, navItems, pageRefs, scrollToLocation, updateLink])

  // handle browser history navigation (aka make it work after my custom voodoo)
  useEffect(() => {
    const pushpop = () => {
      if (pathname !== window.location.pathname) {
        const currentNavItem = navItems.find(
          navItem => navItem.route === window.location.pathmame
        )

        handleClick(currentNavItem.id)
      }
    }

    window.addEventListener('popstate', pushpop)

    return () => {
      window.removeEventListener('popstate', pushpop)
    }
  }, [container, handleClick, navItems, pageRefs, pathname])

  // update nav + page location on page (re)load
  useMemo(() => {
    const active = navItems.find(navItem => navItem.active)
    const current = navItems.find(navItem => navItem.route === pathname)

    if (!active && container) {
      handleClick(current.id)
    }
  }, [container, handleClick, navItems, pathname])

  return {
    fns: {
      click: handleClick,
      toggleNav,
      toggleSubnav,

    },
    active: navItems.find(navItem => navItem.active),
    container,
    handleClick,
    nav: {
      items: navItems,
      click: handleClick,
      toggle: toggleNav,
      updateRef,
      visible,
    },
    pageRefs: {
      _add: updatePageRef,
      ...pageRefs,
    },
    subnav: {
      visible: subnav,
      toggle: toggleSubnav,
    },
    toggleNav,
    toggleSubnav,
    updateContainer,
  }
}

export const useNav = (containerRef) => {
  const [ctx, setCtx] = useState(null)
  const navigation = useSiteNavigation(containerRef)

  const updateNavigation = useCallback(async () => {
    if (!ctx && navigation) {
      console.log('officially updating with', navigation)
      await setCtx(createContext(navigation))
    }
  }, [ctx, navigation, setCtx])

  useEffect(() => {
    if (!ctx && navigation) updateNavigation()
  }, [ctx, navigation, updateNavigation])

  return ctx
}
