import { useContext } from 'react'
import { navContext } from 'contexts/Navigation'
import styled from 'styled-components'

const Underline = () => {
  const { activeLink } = useContext(navContext)

  return (
    <StyledDiv
      className="about-nav-item__underline"
      left={activeLink?.ref?.offsetLeft}
      width={activeLink?.ref?.offsetWidth}
    >
      <div className="content" />
    </StyledDiv>
  )
}

const StyledDiv = styled.div`
  @media screen and (min-width: 641px) {
    margin-left: ${props => props.left}px;
  }

  @media screen and (max-width: 640px) {
    left: ${props => props.left}px;
  }

  width: ${props => props.width}px;
`

export default Underline