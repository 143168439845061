import Tag from './Tag'

const Tags = ({ tags }) => (
  <span className="list-paragraph__tags">
    {tags?.map((tag, index) => (
      <Tag key={`tag_${index}`} name={tag} />
    ))}
  </span>
)

export default Tags