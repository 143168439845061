export const htmlCode1 = `<div class="flex-column">
  <div>This</div>
  <div>is</div>
  <div>a</div>
  <div>flex</div>
  <div>column</div>
</div>`

export const cssCode1 = `.flex-column {
  border: 1px solid #cccccc;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}`

export const cssCode2 = `.flex-column div {
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  padding: 1.25rem;
}`

export const cssCode3 = `${cssCode1.substring(0, cssCode1.length - 2)}\n\twidth: 100%;\n}

${cssCode2}`

export const cssCode4 = `${cssCode1.substring(0, cssCode1.length - 2)}\n\twidth: 100%;\n}

${cssCode2.split(';').map((property, index) => {
  if (index === 0) {
    return `${property};\n\tborder-bottom: 1px solid #cccccc;`
  }

  if (property[property.length - 1] === '}') {
    return property
  }

  return `${property};`
}).join('')}

.flex-column div:last-child {
  border-bottom: none;
}
`