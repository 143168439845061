const InputGroup = ({ children, confirmation = false }) => (
  <div className={`input-group${
    confirmation
      ? ' input-group--confirmation'
      : ''
    }`}>
    {children}
  </div>
)

export default InputGroup