export const terms = [
  {
    name: 'name',
    type: 'string',
    value: 'Ross',
  },
  {
    name: 'title',
    type: 'string',
    value: 'a Senior Full Stack Engineer',
  },
  {
    name: 'education',
    type: 'string',
    value: 'a self-taught programmer',
  },
  {
    name: 'passion',
    type: 'string',
    value: 'an e-commerce enthusiast',
  },
  {
    name: 'hobby',
    type: 'string',
    value: 'a musician',
  },
  {
    name: 'stack',
    type: 'string[]',
    value: ['a JavaScript developer', 'a TypeScript developer', 'a React developer', 'a Node developer', 'a GraphQL developer', 'a Redux developer'],
  },
]