const htmlValidation = (structure, code) => {
  const trimmedCode = code.trim()
  const writtenTags = trimmedCode
    .split('>')
    .filter(element => element !== '')
    .map(element => {
      if (element[0] !== '<') return `<${element.split('<')[1]}`
      return element
    })
    .join('>')
    + '>'

  /**
   * Set up validation based on structure
   */

  const generateTagsFromStructure = structure => {
    return Object.keys(structure)
      .map(key => {          
        if (typeof structure[key].content === 'object') {
          if (structure[key].hasOwnProperty('props')) {
            const tagProps = structure[key].props

            if (Object.keys(structure[key].content).length === 1) {
              return `<${structure[key].name} ${Object.keys(tagProps).map(prop => `${prop}="${tagProps[prop]}"`).join(' ')} />`
            }

            return `<${structure[key].name} ${Object.keys(tagProps).map(prop => `${prop}="${tagProps[prop]}"`).join(' ')}>${generateTagsFromStructure(structure[key].content)}</${structure[key].name}>`
          }

          return `<${structure[key].name}>${generateTagsFromStructure(structure[key].content)}</${structure[key].name}>`
        }

        return `<${structure[key].name}></${structure[key].name}>`
      }).join('')
  }

  const structureTags = generateTagsFromStructure(structure)
  console.log(structureTags, '||', writtenTags)

  /**
   * Compare user-written HTML to the validation structure
   */

  return structureTags === writtenTags
}

export default htmlValidation